$body-text-color = #333
$font-size = 18px
$font-harfang = harfang-pro,sans-serif
$font-harfang-n6 = harfang-pro-n6, harfang-pro, sans-serif
$font-futura = futura-pt,sans-serif

/* COLORS */
$gold = #bc9c3c
$gold-dark = #775b01
$medium-blue = #0095CA
$dark-blue = #005b7b
$brown = #926845

/*
Neue Helvetica 45 Light
300	normal

Neue Helvetica 55 Roman
400	normal

Neue Helvetica 75 Bold
600	normal

Gill Sans Heavy Italic
500	italic

Gill Sans Bold Italic
400 italic
*/

body
  font 15px/1.5 $font-family-sans-serif
  color $body-text-color

.clearfix:before, .clearfix:after
  display table
  content " "

.clearfix
  &:after
    clear both

.reset
  margin 0
  padding 0
  list-style-type none

img
  max-width 100%


a
  color $gold-dark
  &:focus
    text-decoration none
    color $gold
  &:hover
    color $gold
    text-decoration none

p a, li a
  color $gold-dark
  text-decoration underline

p a:hover, li a:hover
  color #000
  text-decoration underline

ul
  margin-bottom 32px

h1{
  font-size 30px
  margin 10px 0 30px
  line-height 1.3
  font-family: $font-harfang
  color:#000;
  font-weight:600;
  text-align:left;
  font-style: normal;
}

h2{
	font-size:24px;
	font-family: $font-harfang
	line-height: 1.3;
	margin-top:1.5em;
	margin-bottom:1em;
	color:#000;
	font-weight:600;
	text-align: left;
	font-style: normal;
}

h3{
	font-size:18px;
	line-height:1.15;
	margin-top:1.15em;
	margin-bottom:1.15em;
	color:#000;
	font-family: $font-harfang-n6
	font-style: normal;
	font-weight: 600;

}

h4{
	font-size:14px;
	line-height:1.48;
	margin-bottom:1.48em;
	color:#000;
	font-family: $font-harfang
	font-style: normal;
	font-weight: 600;
}

span.amp{
  font-family:Baskerville,Palatino,Constantia,"Book Antiqua","URW Palladio L",serif;
  font-style:italic;
}


h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover
  text-decoration underline

.nav > li > a:hover, .nav > li > a:focus
  background none