
//@import "animations.min.css"
@import "bootstrap"
@import "default-styles"
@import "buttons"

/*
Neue Helvetica 45 Light
300	normal

Neue Helvetica 55 Roman
400	normal

Neue Helvetica 75 Bold
600	normal

Gill Sans Heavy Italic
500	italic

Gill Sans Bold Italic
400 italic
*/

.announcement-bar
  background #982424
  text-align center
  color #FFF
  font-size: 15px;
  border-bottom: 1px solid #EEE;
  @media (max-width: 575px)
    font-size 14px
  &:hover
    background #761f1e
  a
    padding 10px 20px
    color #FFF
    display block
    &:hover
      color #FFF
.announcement-bar--no-link
  padding 10px 20px

// Reset Bootstrap at 992px to a fluid width
@media (max-width: 992px)
  .container
    width 98%
    margin 0 1%

 /**
  * Page Header
  */
.header
  background #000 url("../images/bg-header2.png")
  position relative

.navbar-brand
  float none
  display block
  text-align left
  height auto
  line-height inherit
  padding 0
  z-index 99
  make-xs-column(4)
  @media (max-width: 767px)
    width 80%
    padding 0
    margin-top 45px
  @media (max-width: 480px)
    width 60%
    margin 45px 0 0px


.city-phone
  margin-top 50px
  z-index 99
  > p
    font-family $font-futura
    font-weight 300
    color #FFF
    font-size 23px
    text-align center
    line-height 1.4
    > span
      color $gold
      font-size 65%
      display block
      font-family $font-harfang
      a
        color $gold
    @media (max-width: 1200px)
      font-size 18px
    @media (max-width: 1000px)
      font-size 16px
    @media (max-width: 970px)
      font-size 14px
    a
      color #FFF
      text-decoration none
      :hover
        text-decoration underline

.locations-template
  .city-phone
    > p
      font-size 24px
      @media (max-width: 1200px)
        font-size 19px
      @media (max-width: 1000px)
        font-size 16px
      @media (max-width: 970px)
        font-size 14px

.mobile-cities
  margin-top 30px
  @media (max-width: 767px)
    position absolute
    top 0
    left 0
    margin 0
    padding 0
    width 100%
  > div
    position: relative;
    float: left;
    width: 25%;
    min-height: 1px;
    padding-left: 2.5px;
    padding-right: 2.5px;
    z-index 99

.mobile-location
  margin-top 30px
  @media (max-width: 767px)
    position absolute
    top 0
    left 0
    margin 0
    padding 0
    width 100%
    .city-phone
      p
        font-size 18px
      a
        color: #FFF

.mobile-city
  background rgba(#666,0.4)
  padding 5px 0
  color $gold
  font-size 15px
  text-align center
  display block
  @media (max-width: 490px)
    font-size 13px
  @media (max-width: 400px)
    font-size 11px



// Top Level
.navbar
  margin-bottom 0

  .collapse
      display block

  @media (max-width: 767px)
    margin-top -45px
    background none
    .collapse
      display none
    .collapse.in
      display block

.primary-menu
  float none
  @media (max-width: 767px)
    margin-top 40px
  & > li
    text-align center
    &.home
      width 10%
    &.about-our-firm
      width 19%
    &.social-security-disability
      width 29%
    &.articles
      width 11%
    &.locations
      width 17%
    &.contact-us
      width 14%
    & a
      color #FFF
      font-size 19px
      font-family futura-pt,sans-serif
      font-weight 400
      padding 10px 0
      margin 15px 0
      text-decoration none
      display inline-block
      background none
      &:hover
      /.primary-menu .open > a
      /.primary-menu .open > a:hover
      /.primary-menu .open > a:focus
        background none
        padding-bottom 7px
        border-bottom 3px solid #977b25

    @media (max-width: 767px)
      &
      &.home
      &.about-our-firm
      &.social-security-disability
      &.articles
      &.locations
      &.contact-us
        width 100%
        text-align left

        & a
          display block
          padding: 15px 30px
          // Drop down icon
          > span
            display none
          &:hover
            border none
            color $gold
      &.open > a
        padding-bottom 0 !important
        border none
        margin-bottom 0
      &.open > a:hover
        border none
        padding-bottom 0


  & > li a.selected
  & > li.active a
    color $gold

// Spanish
.primary-menu & > li
  &.inicio
    width 14%
  &.sobre-nuestro-bufete
    width 20%
  &.seguro-social-por-incapacidad
    width 30%
  &.ubicaciones
    width 18%
  &.contactenos
    width 18%
  @media (max-width: 767px)
    &
    &.inicio
    &.sobre-nuestro-bufete
    &.seguro-social-por-incapacidad
    &.ubicaciones
    &.contactenos
      width 100%
      text-align left
      & a
        display block
        padding: 10px 30px
        // Drop down icon
        > span
          display none
        &:hover
          border none
          color $gold

// Second Level
.primary-menu > li > ul
.navbar-nav > li > .dropdown-menu
  background #000
  box-shadow 0px -3px 0px #977b25
  margin-top -15px
  width auto
  min-width 100%

.primary-menu .dropdown-menu
  & > li
    & > a
      display block
      padding 4px 20px
      clear both
      font-weight 500
      line-height 1.1
      color #FFF
      white-space nowrap
      font-size 15px
      @media (max-width: 767px)
        padding 0
      &:hover
        border none
        color $gold
  & > li.active a
    color $gold
    background none


.primary-menu .open .dropdown-menu
  @media (max-width: 767px)
    padding-left 20px
  a
  background none
  border none
  &:hover
  &:focus
    color $gold

.navbar-mobile
  display none
  margin-bottom 0
  .hamburger
    text-align right
    color #FFF
    background none
    z-index 999
    text-decoration none
    @extend .clearfix
    &:hover, &:focus
      background none
    span
      line-height 1.2
      font-size 19px
    @media (max-width: 480px)
      padding 6px
  @media (max-width: 767px)
    display block
    position absolute
    right 0
    top -70px
    margin 0
    .hamburger span
      font-size 16px
  @media (max-width: 480px)
    top -55px

.navbar .container
  @media (max-width: 767px)
    width 100%
    margin 0


// Mobile Menu Button
.bubble-wrap
  height 28px
  width 43px
  background-color transparent
  top 0
  right 0
  position relative
  border-radius 50%
  cursor pointer
  -webkit-transform scale(1)
  -webkit-transition all 0.3s ease-out
  display block
  float right
  &.active
    display none

  .bar
    background-color #FFF
    width 30px
    height 3px
    border-radius 35px
    right 3px
    top 0
    @media (max-width: 767px)
      width 27px
      right 5px
      height 1px

  .first
    position absolute
    -webkit-transition all 0.2s ease-out
    @media (max-width: 767px)
      margin-top 2px
    &.active
      margin-top 10px
      transform-origin 50% 50%
      transform rotate(45deg)
      -webkit-transition all 0.2s ease-out
      -webkit-transition-delay .4s

  .second
    margin-top 10px
    position absolute
    -webkit-transition all 0.2s ease-out
    @media (max-width: 767px)
      margin-top 10px
    &.active
      opacity 0
      margin-top 10px
      -webkit-transition all 0.2s ease-out
      -webkit-transition-delay .4s

  .third
    margin-top 20px
    position absolute
    -webkit-transition all 0.2s ease-out
    @media (max-width: 767px)
      margin-top 18px
    &.active
      margin-top 10px
      transform-origin 50% 50%
      transform rotate(-45deg)
      -webkit-transition all 0.2s ease-out
      -webkit-transition-delay .4s


/**
 * Banner
 */
.home
  .banner
    background-size cover
    .image-block
      min-height 361px
      width 100%
.banner
  background #dccfb1 url(../images/banner-home.jpg) top center no-repeat
  width 100%
  @media (max-width: 960px)
    background-size 180%
  @media (max-width: 820px)
    background-size 200%
  @media (max-width: 660px)
    background-size 250%
  .image-block
    min-height 235px
    width 100%
    @media (max-width: 660px)
      min-height 190px
  .covid-message
    //background rgba(255,255,255,.8)
    background rgba(123,100,24,0.9)
    color #FFF
    padding 40px
    margin 20px
    border-top-left-radius: 60px;
    border-bottom-right-radius: 60px;
    @media (max-width: 575px)
      margin-left 0
      margin-right 0
      padding 20px 20px 10px 20px
      border-top-left-radius: 40px;
      border-bottom-right-radius: 40px;
    h2
      margin-top 0
      color #FFF
    a
      color #FFF
      &:hover
        font-weight bold

.home .banner .image-block
.banner .image-block
  @media (max-width: 960px)
    min-height 235px
  @media (max-width: 820px)
      min-height 190px

.four-oh-four
  .banner
    background #dccfb1 url(../images/bg-notfound.jpg) top center no-repeat

.headline
  height 92px
  margin 0 auto
  width 100%
  text-align center
  background #000
  background rgba(0,0,0,0.5)
  color #FFF
  font-family $font-harfang
  font-size 48px
  line-height 92px
  font-weight 300
  font-style normal
  @media (max-width: 960px)
    height auto
    font-size 32px
    line-height 40px
    padding 10px 5px
  @media (max-width: 700px)
    font-size 28px
    line-height 30px
    padding 10px 20px
    > img
      display none
  @media (max-width: 640px)
    background #000


.ie7 .headline,
.ie8 .headline
  background transparent url(../images/ie-banner-bg.png) top left repeat-x

.tagline
  height 42px
  margin 0 auto
  width 100%
  text-align center
  background rgb(123,100,24)
  background rgba(123,100,24,0.8)
  @media (max-width: 960px)
    height auto
  @media (max-width: 640px)
    background #7B6418
  h2
    color #FFF
    font-family $font-futura
    line-height 42px
    font-size 17px
    font-weight 500
    margin 0
    text-align center
    @media (max-width: 960px)
      font-size 16px
      line-height 18px
      padding 5px 30px
    @media (max-width: 360px)
      font-size:14px;

.ie7 .tagline, .ie8 .tagline
  background transparent url(../images/ie-banner-bg.png) bottom left repeat-x

.ornament
  padding inherit

.call-to-action
  background #000
  border-radius 8px
  -moz-border-radius 8px
  -webkit-border-radius 8px
  padding 25px 0
  margin 25px auto 40px
  p
    text-align center
    a
      color #bc9c3c
      text-decoration underline
      &:hover
        color #FFF
    &.text01
      color #aa8c32
      font-family Arial, Helvetica, sans-serif
      font-size 15px
      text-transform uppercase
      margin 0
      line-height 1.2
      @media (max-width: 480px)
        font-size:13px;
        padding:0 2%
    &.text02
      color #FFF
      font-family harfang-pro
      font-size 29px
      margin 5px 0 12px
      line-height 1.2
      @media (max-width: 480px)
        font-size 23px
        padding:0 2%
    &.text03
      color #FFF
      font-family Georgia, "Times New Roman", Times, serif
      font-size 19px
      margin 10px 0 0 0
      line-height 1.2
      @media (max-width: 480px)
        font-size 16px
        padding:0 2%
.footer
  width 100%
  background #191919 url(../images/bg-header2.png) top center repeat
  border-top 12px solid #5a4a1b
  padding 20px 0 40px 0
  p
    color #AAA
    font-size 13px
    font-weight normal
    a
      &:hover
        text-decoration underline

.footer p a,
.footer p a:visited
  text-decoration none
  color #BC9C3C

.sig
  float right

/**
 * Home Page Content Styles
 */

.home
  .boxes
    margin-top 20px
    p
      font-size 13px
      line-height: 1.6
    ul
      font-size 13px
      padding-left 0
      margin-top 20px
      margin-bottom 20px
    li
      list-style-type none
      background #FFF url(../images/icon-checkmark.png) 0 2px no-repeat
      padding-left 20px
      margin-bottom 5px
    h4
      font-family $font-family-base
      margin-bottom 12px
      margin-top -1px
  .testimonial-list li.tblock
      make-sm-column(3)


/**
 * General Content Styles
 */

.content
  make-sm-column(8)
  make-sm-column-push(4)
  padding 30px

  h1
    font-size 30px
    margin 10px 0 30px
    line-height 1.3
  p
    margin-bottom 30px
  ul li
    margin-bottom 20px

.sidebar
  make-sm-column(4)
  make-sm-column-pull(8)
  padding 10px

// Spanish
.general-page.es
  .content
    make-sm-column(8)
    make-sm-column-push(2)
  .sidebar
    display none

/**
 * About Template Content Styles
 */

.about-page
  .content
    make-sm-column(12)
    make-sm-column-push(0)
    padding-bottom 0
  .row h2
    font-size 32px
    margin-top 35px
    text-align center
    @media (max-width: 500px)
      font-size 28px
    @media (max-width: 500px)
      font-size 21px
  .sidebar
    padding-top 0
    margin-top 0
    padding-bottom 0
  .why-choose
    @media (max-width: 500px)
      margin-top 0
    li
      .number
        @media (max-width: 400px)
          font-size 32px
          text-align center



/**
 * Attorney and Staff Listing
 */
.bio-list
  .bio-block
    make-md-column(2.4)
    height 320px
    a
      img
        padding 4px
        border 1px solid #d2bb87
      &:hover
        img
          opacity .8
          border 1px solid #000
    h4
      font-weight normal
      margin 4px 0 0 0
    p
      margin 0
      &.position
        font-family futura-pt,sans-serif
        font-size 15px
        color #666
    @media (max-width: 992px)
      make-xs-column(3)
    @media (max-width: 800px)
      make-xs-column(4)
    @media (max-width: 600px)
      make-xs-column(6)
    @media (max-width: 360px)
      height: 280px;


.banner.inner-bio
  @media (max-width: 960px)
    background-color #000
    background-size 200%
    background-position 62% 0% !important
  @media (max-width: 680px)
    background-size cover
  .image-block
    height 307px
    padding-left 10px
    @media (max-width: 960px)
      height auto
      width auto
      padding 0 2% 60px
    @media (max-width: 500px)
      padding 0 0 60px
    h1
      color #FFF
      font-family harfang-pro
      font-size 48px
      line-height 92px
      font-weight 300
      font-style normal
      text-align left
      text-shadow 0px 0px 10px #000
      margin 0
      padding-top 120px
      @media (max-width: 960px)
        font-size 36px
        line-height 56px
        text-shadow 0px 0px 6px #000
      @media (max-width: 680px)
        font-size 36px
        line-height 42px
        padding-top 90px
      @media (max-width: 500px)
        font-size 24px
        line-height 28px
    h2
      font-family futura-pt,sans-serif
      font-size 18px
      text-align left
      color #FFF
      margin 0
      font-weight normal
      text-shadow 0px 0px 10px #000
  .headline
    height 35px
    @media (max-width: 960px)
      height 20px
    @media (max-width: 680px)
      background: rgba(0,0,0,0.5);
    @media (max-width: 500px)
      display none
  .tagline
    height 13px
    background rgb(123,100,24)
  p
    &.icon
      float left
      color #FFF
      font-family futura-pt,sans-serif
      font-weight 500
      margin 25px 30px 0 0
      font-size 14px
      @media (max-width: 680px)
        margin-top 10px
      @media (max-width: 500px)
        margin-top 3px
      a
        color #FFF
        text-decoration none
        &:hover
          text-decoration underline
    &.phone
      background transparent url(../images/icon-phone.png) 4px 3px no-repeat
      padding-left 20px

.share-links
  display none

.our-attorneys .content
  make-sm-column-push(0)

.our-attorneys .sidebar
  padding-left 30px
  make-sm-column-pull(0)
  h3
    text-transform uppercase
    font-size 16px
  ul
    list-style none
    margin 0
    padding 0
    > li
      font-size 12px
      font-family $font-harfang
      margin-bottom 4px
      color #777

/**
 * General Page with Sidebar
 */

.sidebar-widget
  margin-bottom 40px
  padding 25px

.sidebar-title
  color #000
  font-family $font-family-sans-serif
  font-size 14px
  font-weight 600

// Sidebar Menu
.sidebar-menu
  padding 25px
  margin 34px 5px 0 0
  border-radius 10px
  @media (max-width: 880px)
    margin-right 5px
    margin-bottom 20px

  p a
    color #666
    font-size 13px
    font-family $font-family-sans-serif
    font-weight 600
    text-decoration none
    &.selected
      color $gold-dark
    &:hover
      text-decoration underline
      color $gold-dark
  ul
    list-style none
    padding 0
    li
      font-size 13px
      font-family $font-family-sans-serif
      font-weight 600
      border-top 1px solid #ccc
      a
        color #666
        padding: 9px 0;
        display: block;
        line-height: 1.3;
        text-decoration none
        &.selected
          color $gold-dark
      &:hover
        text-decoration underline
        color $gold-dark

/**
 * Testimonials Template
 */
.testimonial-template
  .content
    make-sm-column(12)
    make-sm-column-push(0)

.testimonial-list
  margin 0
  padding 0
  li.tblock
      list-style-type none
      margin 15px 0 35px
      make-sm-column(4)
      @media (max-width: 767px)
        position relative !important
        top auto !important
    p
      &.testimonial
        font-size 15px
        font-family harfang-pro
        color #777
        font-weight 400
        font-style italic
        line-height 1.6
        margin-top 10px
        margin-bottom 10px
        padding-top 15px
        border-top 3px solid #999
      &.source
        font-family harfang-pro
        font-size 13px
        font-weight bold
        text-transform uppercase

/**
 * Why Reyes and Reyes Template
 */
.why-choose
  margin 35px auto 15px
  make-md-column(10)
  make-md-column-push(1)
  padding 0
  li
    make-sm-column(6)
    list-style-type none
    .number
      color #bdb188
      font-size 54px
      font-family "Times New Roman", Times, serif
      font-weight 300
      font-style normal
      width 15%
      float left
      line-height 36px
      text-align left
      padding-right 15px
      letter-spacing -4px
      @media (max-width: 767px)
        text-align center
      @media (max-width: 480px)
        text-align left
        width auto
        float none
        margin-bottom 5px
    .reason
      border-top 1px solid #bdb188
      width 85%
      float left
      padding-bottom 15px
      @media (max-width: 480px)
        width auto
        float none
      h3
        text-transform capitalize
        margin-top 18px
        font-size 17px

.why-choose li:nth-child(odd),.why-choose li.odd
  margin-right 0
  clear left

.ie7
  .why-choose
    li
      .number
        padding-bottom 30px


/**
 * Articles / Blog
 */
.blog-template .content
  padding-top 40px

.blog-post
  .post-title
    font-size 30px
    text-align left
    line-height 1.3
    a
      color #000
  .post-meta
    font-size 12px
    color #666
  .blog-post-divider
    height 5px
    background #CCC

.single-post
  .post-title
    font-size 30px
    text-align left
    line-height 1.3
    font-weight 600
  .post-meta
    font-size 14px
    color #666

.pagination
  display inline-block
  padding-left 0
  margin 20px 0
  border-radius: 4px
  li
    display: inline
    a
      position: relative;
      float: left;
      padding: 7px 15px;
      margin-left: -1px;
      line-height: 1.42857143;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #ccc;
  :first-child > a
    margin-left 0
    border-top-left-radius 4px
    border-bottom-left-radius 4px
  .active > a, .pagination>.active>a:hover, .pagination>.active>a:focus
    z-index: 2;
    color: #fff;
    background-color: $gold-dark;
    border-color: $gold-dark;
    cursor: default;
  :last-child > a
    margin-left 0
    border-left: 0px solid #CCC
    border-top-right-radius 4px
    border-bottom-right-radius 4px

.social-icons
  li
    margin 15px 0
  a
    font-size 17px
    color $gold-dark
    font-family $font-family-sans-serif
    text-decoration none
    i
      font-size 180%
      margin-right 5px
    &:hover
      text-decoration none

/**
 * Locations
 */
.locations-template
  .header-address
    color #CCC
    font-size 17px
    line-height 1.3
    margin-top 55px
    font-family $font-futura
    @media (max-width: 1200px)
      margin-top 45px
    @media (max-width: 960px)
      font-size 15px

  .city-phone
    @media (max-width: 768px)
      margin-top 50px
    > p
      @media (max-width: 1000px)
        font-size 19px
      @media (max-width: 768px)
        font-size 28px

  .content
    make-sm-column-push(0)
    .text03
      font-size 28px
      font-family $font-family-base
      font-weight 600
      margin-bottom 20px
  .sidebar
    make-sm-column-pull(0)

  .locations-contact
    background black
    margin-top 30px
    text-align center
    padding 30px 20px
    .text01
      @extend .call-to-action p.text01
      margin-bottom 20px
    .text02
      @extend .call-to-action p.text02
      font-weight 300
      margin 20px 35px
    .text03,
    .text03 a
      font-size 28px
      font-family $font-family-base
      font-weight 600
      color #FFF
      margin-bottom 20px
    .text04
      text-transform none
      font-size: 24px;
      color: #8E8E8E;
      margin-top 30px
      text-align center

  .why-datamark-section
    .headline
      background none
      color #000
      margin-bottom 40px
      margin-top 30px
      @media (max-width: 768px)
        margin-bottom 15px
      @media (max-width: 480px)
        margin-bottom 15px

/**
 * Locations
 */
.contact-template
  .location
    padding-bottom 20px
    border-bottom 1px #c8c8c8 solid
    margin-bottom 20px
    h2
      margin-bottom 15px
    .text03
      font-size 20px
      font-family $font-family-base
      font-weight 600
      color $gold-dark
      margin-top 5px
      margin-bottom 15px
    &:last-child
      border none


// Spanish Bar
.language-bar
  width 100%
  background-color #000
  font-size 13px
  text-align center
  line-height 30px
  a
    text-decoration underline
    color $gold
    &:hover
      color #FFF


// Disable All Animations for mobile
@media (max-width: 767px)
  .animated
    animation none !important
    transition-property none !important
    transform none !important
    opacity 1 !important